<template>
    <main>
        <!-- ANEXAR DOCUMENTOS === SECRETÁRIA -->
        <b-row class="mb-3" v-if="!['SECRETARY', 'MANAGER'].includes(role)">
            <b-col lg="6" md="12">
                <div class="white-box">
                    <div class="content">
                    <div class="header">
                        <div class="main-title">
                            Histórico de documentos
                            <Loading :class="{ 'icon-loading': true, reload: true, loading: loadingDocs }" @click="() => !loadingDocs && getAllDocs()"/>
                        </div>
                        <b-button
                        v-can="'FpPron6'"
                        variant="link"
                        @click="fileType = 'doc'"
                        v-b-modal.attachment-files-generic-modal
                        :disabled="inactivePatient"
                        >
                        <p> <Clip />Anexar arquivo</p>
                        </b-button>
                    </div>
                    </div>

                    <div class="list-area">
                        <perfect-scrollbar
                            :class="{'expanded': expandDocs}">
                            <p class="info-message-box" v-if="!docs">
                                O paciente ainda não possui histórico de documentos.
                            </p>
                            <ul class="list" v-else>
                                <li
                                    v-for="(doc, index) of docs"
                                    :key="index"
                                    @click="setActiveDoc(doc)"
                                >

                                    <div class="d-flex container">
                                        <span v-if="doc.length === 1" class="icon">
                                            <ImageIcon  />
                                        </span>
                                        <span v-if="doc.length > 1" class="icon">
                                            <Gallery />
                                        </span>
                                        <div class="container-data-files">
                                            <div class="data-files">
                                            <div class="options" v-if="doc[0].type_id === 'extern'">
                                                <External class="more-icon"/>
                                                <div class="menu">
                                                <p>Documento criado em outra instituição/clínica</p>
                                                </div>
                                            </div>
                                            <p class="name">{{ getDocumentName(doc) }}</p>
                                            </div>
                                            <p class="info">{{moment(doc[0].created_at).format('DD [de] MMMM [de] YYYY')}}</p>
                                        </div>

                                        <div class="ml-auto flex-inline">
                                            <Edit v-can="'FpPron7'" class="attendance-file-option-edit" :changeOnlyName="doc[0]?.path?.includes('Historico Paciente')" @click="(ev) => showEditDocumentModal(ev, doc[0], doc)" />
                                            <Delete v-can="'FpPron9'" class="attendance-file-option-delete" @click="(ev) => showExcludeDocumentModal(ev, doc[0])"/>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </perfect-scrollbar>
                        <div class="expand" style="margin-top: 20px; height: 30px;">
                            <div style="cursor: pointer" v-if="docs"
                            @click="expandDocs = !expandDocs">
                            <span v-if="!expandDocs">Expandir<ChevronDown /></span>
                            <span v-else>Reduzir<ChevronUp /></span>
                            </div>
                        </div>
                    </div>

                </div>
            </b-col>
            <b-col lg="6" md="12" v-can="'FpPron4'">
                <div class="white-box">
                    <div class="content">
                    <div class="header">
                        <div class="main-title">
                            Histórico de exames
                            <Loading :class="{ 'icon-loading': true, reload: true, loading: loadingExams }" @click="() => !loadingExams && getAllExams()"/>
                        </div>
                        <b-button
                        v-can="'FpPron6'"
                        variant="link"
                        @click="fileType = 'exam'"
                        v-b-modal.attachment-files-generic-modal
                        :disabled="inactivePatient"
                        >
                        <p> <Clip />Anexar arquivo</p>
                        </b-button>
                    </div>
                    </div>

                    <div class="list-area">
                    <perfect-scrollbar :class="{'expanded': expandExams}">
                        <p class="info-message-box" v-if="!exams">
                            O paciente ainda não possui histórico de exames.
                        </p>
                        <ul class="list" v-else>
                        <li
                            v-for="(exame, index) of exams"
                            :key="index"
                            v-b-modal.exams-modal
                            @click="setActiveExam(exame)"
                        >
                        <div class="d-flex container">
                            <span v-if="exame.length === 1" class="icon">
                            <ImageIcon  />
                            </span>
                            <span v-if="exame.length > 1" class="icon">
                            <Gallery />
                            </span>
                            <div class="container-data-files">
                            <div class="data-files">
                                <div class="options" v-if="exame[0].type_id === 'extern'">
                                <External class="more-icon"/>
                                <div class="menu">
                                    <p>Exame feito em outra instituição/clínica</p>
                                </div>
                                </div>
                                <p class="name">{{ getDocumentName(exame) }}</p>
                            </div>

                            <p class="info">{{moment(exame[0].created_at).format('DD [de] MMMM [de] YYYY')}}</p>

                            </div>

                            <div class="ml-auto flex-inline">
                            <Edit v-can="'FpPron8'" class="attendance-file-option-edit" @click="(ev) => showEditExamModal(ev, exame[0], exame)" />
                            <Delete v-can="'FpPron10'" class="attendance-file-option-delete" @click="(ev) => showExcludeExamModal(ev, exame)"/>
                            </div>
                        </div>
                        </li>
                        </ul>
                    </perfect-scrollbar>
                    <div class="expand" style="margin-top: 20px; height: 30px;">
                        <div style="cursor: pointer" v-if="exams"
                        @click="expandExams = !expandExams">
                        <span v-if="!expandExams">Expandir<ChevronDown /></span>
                        <span v-else>Reduzir<ChevronUp /></span>
                        </div>
                    </div>
                    </div>

                </div>
            </b-col>
        </b-row>
        <!-- FIM -->
        <!-- ANEXAR DOCUMENTOS !== SECRETÁRIA -->
        <b-row v-else>
            <b-col lg="6" md="12">
                <div class="white-box">
                    <div class="content">
                        <div class="header">
                        <div class="main-title">
                            Histórico de documentos
                            <Loading :class="{ 'icon-loading': true, reload: true, loading: loadingDocs }" @click="() => !loadingDocs && getAllDocs()"/>
                        </div>
                        <b-button
                            v-can="'FpPron6'"
                            variant="link"
                            @click="fileType = 'doc'"
                            v-b-modal.attachment-files-generic-modal
                            :disabled="inactivePatient"
                        >
                            <p> <Clip />Anexar arquivo</p>
                        </b-button>
                        </div>
                    </div>
                    <div class="list-area">
                        <perfect-scrollbar
                        :class="{'expanded': expandDocs}">
                        <p
                            class="info-message-box"
                            v-if="!docs"
                        >
                            O paciente ainda não possui histórico de documentos.
                        </p>
                        <ul class="list" v-else>
                          <template
                            v-for="(doc, index) of docs"
                          >
                            <li
                              :key="index"
                              :class="{ 'opened': openedDocs[doc.id] }"
                              @click="setActiveDoc(doc)"
                            >

                              <div class="d-flex container">
                                <span v-if="doc.length === 1" class="icon">
                                <ImageIcon  />
                                </span>
                                <span v-if="doc.length > 1" class="icon">
                                <Gallery />
                                </span>
                                <div class="container-data-files">
                                  <div class="data-files">
                                    <div class="options" v-if="doc[0].type_id === 'extern'">
                                      <External class="more-icon"/>
                                      <div class="menu">
                                        <p>Documento criado em outra instituição/clínica</p>
                                      </div>
                                    </div>

                                    <p class="name">{{ getDocumentName(doc) }}</p>
                                  </div>

                                  <p class="info">{{moment(doc[0].created_at).format('DD [de] MMMM [de] YYYY')}}</p>
                                </div>

                                <div class="ml-auto flex-inline">
                                  <Edit v-can="'FpPron7'" class="attendance-file-option-edit" @click="(ev) => showEditDocumentModal(ev, doc[0], doc)" />
                                  <Delete v-can="'FpPron9'" class="attendance-file-option-delete" @click="(ev) => showExcludeDocumentModal(ev, doc[0])"/>
                                </div>
                              </div>
                            </li>
                          </template>

                        </ul>
                        </perfect-scrollbar>
                        <div class="expand" v-if="docs">
                          <div style="cursor: pointer"
                          @click="expandDocs = !expandDocs">
                              <span v-if="!expandDocs">Expandir<ChevronDown /></span>
                              <span v-else>Reduzir<ChevronUp /></span>
                          </div>
                        </div>
                    </div>
                </div>
            </b-col>

            <b-col lg="6" md="12">
                <div class="white-box">
                <div class="content">
                    <div class="header">
                    <div class="main-title">
                        Histórico de exames
                        <Loading :class="{ 'icon-loading': true, reload: true, loading: loadingExams }" @click="() => !loadingExams && getAllExams()"/>
                    </div>
                    <b-button
                        v-can="'FpPron6'"
                        variant="link"
                        @click="fileType = 'exam'"
                        v-b-modal.attachment-files-generic-modal
                        :disabled="inactivePatient"
                    >
                        <p> <Clip />Anexar arquivo</p>
                    </b-button>
                    </div>
                </div>

                <div class="list-area">
                    <perfect-scrollbar
                    :class="{'expanded': expandExams}">
                    <p
                        class="info-message-box"
                        v-if="!exams"
                    >
                        O paciente ainda não possui histórico de exames.
                    </p>
                    <ul class="list" v-else>
                        <li
                        v-for="(exame, index) of exams"
                        :key="index"
                        v-b-modal.exams-modal
                        @click="setActiveExam(exame)"
                        >
                        <div class="d-flex container">
                            <span v-if="exame.length === 1" class="icon">
                            <ImageIcon  />
                            </span>
                            <span v-if="exame.length > 1" class="icon">
                            <Gallery />
                            </span>
                            <div class="container-data-files">
                            <div class="data-files">
                                <div class="options" v-if="exame[0].type_id === 'extern'">
                                <External class="more-icon"/>
                                <div class="menu">
                                    <p>Exame feito em outra instituição/clínica</p>
                                </div>
                                </div>
                                <p class="name">{{ getDocumentName(exame) }}</p>
                            </div>

                            <div class="exam-info-container">
                                <p class="info">{{moment(exame[0].created_at).format('DD [de] MMMM [de] YYYY')}}
                                <span v-if="exame.at(-1).medical_report"
                                    :class="{ 'orange-background': exame.at(-1).medical_report.status === 'FINISHED',
                                    'blue-background': exame.at(-1).medical_report.status === 'PENDING'}">
                                    {{ exame.at(-1).medical_report.status === 'FINISHED' ? 'Laudado' : 'Laudo pendente' }}
                                </span>
                                </p>
                            </div>
                            </div>

                            <div class="ml-auto flex-inline">
                            <Edit v-can="'FpPron8'" class="attendance-file-option-edit" @click="(ev) => showEditExamModal(ev, exame[0], exame)" />
                            <Delete v-can="'FpPron10'" class="attendance-file-option-delete" @click="(ev) => showExcludeExamModal(ev, exame)"/>
                            </div>
                        </div>
                        </li>
                    </ul>
                    </perfect-scrollbar>
                    <div class="expand">
                    <div style="cursor: pointer" v-if="exams"
                    @click="expandExams = !expandExams">
                        <span v-if="!expandExams">Expandir<ChevronDown /></span>
                        <span v-else>Reduzir<ChevronUp /></span>
                    </div>
                    </div>
                </div>
                </div>
            </b-col>
        </b-row>
        <!-- FIM -->

        <EditDocumentModal 
            :attendanceDocumentFile="attendanceDocumentFile" 
            :getAllDocs="getAllDocs" 
        />

        <EditExamModal 
            :attendanceExamFile="attendanceExamFile" 
            :getAllExams="getAllExams" 
        />

        <AttachmentFilesGenericModal
            :fileType="fileType"
            :patientId="$route.params.id"
            @uploadedFiles="uploadedFiles"
        />

        <AppointmentDocsModal
            :docs="examDocs"
            :typeDoc="typeDoc"
            @openDocumentFile="openDocumentFile"
        />

        <DocumentModal :documents="documents" @close-modal="clearDocuments" />

        <ExcludeDocumentModal
            :document="documentGrouped"
            :getAllDocs="getAllDocs"
            :getDocumentName="getDocumentName"
        />

        <ExcludeExamModal
            :document="documentGrouped"
            :getAllExams="getAllExams"
            :getDocumentName="getDocumentName"
        />

        <EditGroupFilesModal
            :document="document"
            :group="documentGrouped"
            :patientId="$route.params.id"
            :getAllExams="getAllExams"
            :getAllDocs="getAllDocs"
            @clearDocument="clearDocument"
        />
    </main>
</template>
<script>
import {  getCurrentClinic } from '@/utils/localStorageManager'
import { getDocTypes } from '@/utils/documentHelper.js'
import {isObject} from "@/utils/dataHelper"

export default {
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),
        AppointmentDocsModal: () => import('../../components/General/AppointmentDocsModal.vue'),
        AttachmentFilesGenericModal: () => import('@/components/General/AttachmentFilesGenericModal'),
        EditGroupFilesModal: () => import('@/components/General/EditGroupFilesModal'),
        DocumentModal: () => import('@/components/General/DocumentModal'),
        ExcludeDocumentModal: () => import('@/components/General/ExcludeDocumentModal'),
        EditDocumentModal: () => import('@/components/General/EditDocumentModal'),
        EditExamModal: () => import('@/components/General/EditExamModal'),
        ExcludeExamModal: () => import('@/components/General/ExcludeExamModal'),
        Clip: () => import('@/assets/icons/clip.svg'),
        Gallery: () => import('@/assets/icons/gallery.svg'),
        ImageIcon: () => import('@/assets/icons/single-image.svg'),
        External: () => import('@/assets/icons/external.svg'),
        Edit: () => import('@/assets/icons/edit.svg'),
        Delete: () => import('@/assets/icons/delete.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        ChevronUp: () => import('@/assets/icons/chevron-up.svg'),
    },
    props: {
        patient: {
            type: Object,
            required: true,
        }
    },
    async mounted() {
        await this.getAllDocs();
        await this.getAllExams();
    },
    computed: {
        inactivePatient() {
            return false; // this.dashboardData.patient?.delete_reason;
        }
    },
    data() {
        const _clinic = getCurrentClinic();
        return {
            clinic: _clinic,
            role: _clinic.role,
            loadingDocs: false,
            loadingExams: false,
            fileType: 'exam', 
            expandDocs: false,
            expandExams: false,
            exams: null,
            docs: null,
            examDocs: [],
            openedDocs: {},
            typeDoc: getDocTypes(),
            documents: [],
            documentGrouped: [],
            document: null,
            attendanceDocumentFile: null,
            attendanceExamFile: null,
        }
    },
    methods: {
        async uploadedFiles() {
            this.fileType === 'doc' ?
                await this.getAllDocs() :
                await this.getAllExams()
        },
        async getAllDocs() {
            this.loadingDocs = true
            try {
                const response = await this.api.getAllDocs(this.$route.params.id)
                if(isObject(response.data)) {
                  this.docs = response.data
                }
                else {
                  this.docs = null
                }
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.loadingDocs = false
            }
        },
        async getAllExams() {
            this.loadingExams = true
            try {
                const response = await this.api.getAllExams(this.$route.params.id)
                if(isObject(response.data)) {
                  this.exams = response.data
                }
                else {
                  this.exams = null
                }
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.loadingExams = false
            }
        },
        setActiveDoc(doc){
            if(['SECRETARY', 'MANAGER'].includes(this.role) && doc[0].type_id === '0') {
                this.$toast.error('Você não possui permissão para visualizar esse documento!');
                return
            }
            this.openDocumentFile(doc)
        },
        async setActiveExam(exam){
            if(['SECRETARY', 'MANAGER'].includes(this.role) && exam[0].type_id === '0') {
                this.$toast.error('Você não possui permissão para visualizar esse documento!');
                return
            }

            if (exam.at(-1).medical_report) {
                this.examDocs = []
                const examDocument = exam
                examDocument.type_id = null
                this.examDocs.push(examDocument)
                if (exam.at(-1).medical_report.attendance_id) {
                const res = await this.api.getAttendanceFiles(exam.at(-1).medical_report.attendance_id)
                const file = res.data.find( doc => { return doc.type_id === '11' })
                if (file) {
                    this.examDocs.push(file)
                }
                }
                this.$bvModal.show('appointment-docs-modal')
                return
            }
            this.openDocumentFile(exam)
        },
        openDocumentFile(document) {
            this.documents = document
            this.$bvModal.show('document-modal')
        },
        getDocumentName(arrayDocument) {
            const document = arrayDocument[0]
            if (!document) return ''
            if (arrayDocument.length > 1) {
                return `${document.group_name}`
            } else if (document.custom_name) {
                return document.custom_name
            } else if(document.type_id) {
                return this.typeDoc[document.type_id]
            }
        },
        showEditDocumentModal(ev, doc, documentArray) {
            ev.stopPropagation()
            this.document = doc
            this.attendanceDocumentFile = doc
            if (documentArray.length > 1) {
                this.documentGrouped = documentArray
                this.$bvModal.show('edit-groups-file-modal')
                return
            }
            this.$bvModal.show('edit-document-modal')
        },
        showExcludeExamModal(ev, examArray) {
            ev.stopPropagation()
            if (Array.isArray(examArray)) {
                this.documentGrouped = examArray
            } else {
                this.documentGrouped = [examArray]
            }
            this.$bvModal.show('exclude-exam-modal')
        },
        showEditExamModal(ev, exam, examArray) {
            ev.stopPropagation()
            this.document = exam
            this.attendanceExamFile = exam
            if (examArray.length > 1) {
                this.documentGrouped = examArray
                this.$bvModal.show('edit-groups-file-modal')
                return
            }
            this.$bvModal.show('edit-exam-modal')
        },
        clearDocuments() {
            this.documents = []
        },
        clearDocument() {
            this.documentGrouped = []
            this.document = null
        },
        showExcludeDocumentModal(ev, documentArray) {
            ev.stopPropagation()
            if (Array.isArray(documentArray)) {
                this.documentGrouped = documentArray
            } else {
                this.documentGrouped = [documentArray]
            }

            this.$bvModal.show('exclude-document-modal')
        },
    },
}
</script>
<style lang="scss" scoped>
.white-box {
    width: 100%;
    background-color: var(--neutral-000);
    border-radius: 8px;
    border: 1px solid #D9DFF2;
    border-radius: 8px;
    margin: 10px;

    &.w-344 {
    width: 344px;
    }

    .content {
    padding: 24px;

    &.with-separator {
        border-bottom: 1px solid var(--neutral-200);
    }

    .hide-btn {
        color: var(--blue-500);
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .main-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            font-family: 'Red Hat Display';
            font-weight: 700;
            font-size: 18px;
            color: var(--dark-blue);
            margin-bottom: 0px;
            white-space: nowrap;

            .icon-loading {
                width: 20px;
                height: 20px;
            }
        }
    }
}

    .labels {
        display: flex;
        flex-direction: row;

        .label {
        font-weight: 600;
        font-size: 16px;
        color: var(--type-active);
        padding-left: 40px;
        position: relative;

        &:last-child {
            margin-left: 24px;
        }

        &:before {
            width: 24px;
            height: 2px;
            background-color: var(--orange);
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: ' ';
        }

        &:after {
            width: 8px;
            height: 8px;
            background-color: var(--orange);
            border-radius: 4px;
            position: absolute;
            left: 7.5px;
            top: 50%;
            transform: translateY(-50%);
            content: ' ';
        }

        &.orange {
            &:before {
            background-color: var(--orange);
            }

            &:after {
            background-color: var(--orange);
            }
        }

        &.blue {
            &:before {
            background-color: var(--blue-500);
            }

            &:after {
            background-color: var(--blue-500);
            }
        }
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
        padding: 15px 24px 15px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        cursor: pointer;
        }
    }
    
    .info-message-box {
        background-color: #e6f9ff;
        border-radius: 8px;
        padding: 16px 26px;
        display: flex;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        align-items: center;
        margin: 0 24px;

        .icon {
            width: 24px;
            height: 24px;
            margin-right: 25px;
            stroke: #0088b3;
        }
    }
}
.list-area {
      margin: -24px 0 24px 0;

      .info-message-box {
        margin-left: 24px;
      }

      .ps {
        /* overflow: unset !important; */
        height: 165px;
        transition: 0.5s;

        &.expanded {
          padding-bottom: 105px;
          height: 300px !important;
        }
      }

      .list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          position: relative;
          padding: 15px 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          &:before {
            width: calc(100% - 48px);
            height: 1px;
            background-color: var(--neutral-200);
            position: absolute;
            bottom: 0;
            left: 24px;
            content: ' ';
          }

          &:hover {
            background-color: var(--neutral-100);
          }

          .container {
            .container-data-files {
              .data-files {
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }

            .options {
              cursor: pointer;
              position: relative;
              display: inline-block;

              .more-icon-dots {
                width: 24px;
                height: 24px;
                stroke: var(--neutral-500);
                transition: all 0.5s;
                cursor: pointer;
              }

              .menu-dots {
                width: 160px;
                position: absolute;
                top: 28px;
                right: 0;
                background: var(--neutral-000);
                border: 1px solid var(--neutral-200);
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                border-radius: 8px;
                opacity: 0;
                transition: all 0.5s;
                flex-direction: column;
                align-items: start;
                z-index: 1;
                display: none;

                .mtb-12 {
                  margin: 12px 0;
                }

                .btn {
                  display: flex;
                  font-weight: 600;
                  width: 100%;
                  padding: 24px 24px 12px 24px;

                  &.remove {
                    color: var(--states-error);
                  }
                }

                .btn + .btn {
                  display: flex;
                  font-weight: 600;
                  width: 100%;
                  padding: 12px 24px 24px 24px;

                  &.remove {
                    color: var(--states-error);
                  }
                }

              }

              &:hover {
                .more-icon-dots {
                  transform: rotate(90deg);
                }

                .menu-dots {
                  opacity: 1;
                  display: flex;
                }
              }
            }
          }

          .options {
            cursor: pointer;
            width: 18px;
            height: 24px;
            margin-right: 5px;
            position: relative;
            display: inline-block;

            .more-icon {
              width: 18px;
              height: 18px;
              transition: all 0.5s;
              cursor: pointer;
            }

            .menu {
              width: 133px;
              position: absolute;
              background: var(--neutral-000);
              border: 1px solid var(--neutral-200);
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
              border-radius: 8px;
              padding: 5px;
              opacity: 0;
              transition: all 0.5s;
              flex-direction: column;
              align-items: start;
              display: none;
              background-color: var(--neutral-600);

              p {
                color: #ffffff;
                font-family: Nunito Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: center;
              }
            }

            &:hover {
              .menu {
                opacity: 1;
                display: flex;
                z-index: 10;
              }
            }
          }

          .name {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-active);
          }

          .exam-info-container {
            span {
              color: var(--neutral-600);
              font-weight: 600;
              font-size: 12px;
              margin-left: 5px;
              padding: 0 5px;
              border-radius: 100px;
              width: fit-content !important;
              height: fit-content !important;
              &.blue-background {
                background-color: var(--neutral-200);
              }

              &.orange-background {
                background-color: var(--light-orange-200);
              }
            }
          }

          .info {
            font-weight: 600;
            font-size: 12px;
            color: var(--type-placeholder);
            white-space: nowrap;

            > span {
              width: 8px;
              height: 8px;
              margin-right: 8px;
              background-color: var(--states-success);
              border-radius: 4px;
              display: inline-block;

              &.orange {
                background-color: var(--light-orange-500);
              }
            }
          }
        }
      }

      .expand {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: #305BF2;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          margin-right: 3px;

          svg {
            stroke: #305BF2;
            width: 17px;
            height: 17px;
          }
        }
      }
    }
    .icon {
      svg {
        stroke: var(--neutral-500);
        width: 24px;
        height: 24px;
      }
      margin-right: 5%;
    }
    .flex-inline {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }
    .attendance-file-option-edit {
        width: 22px;
        height: 22px;
        stroke: var(--blue-500);
    }
    .attendance-file-option-delete {
        width: 22px;
        height: 22px;
        margin-left: 5px;
    }
</style>